import React from "react";
import abstractGridLines from "../../../assets/images/abstract-gridLines.png";
import selfie from "../../../assets/images/abstract-js.png";
import resumePDF from "../../../assets/pdfs/Samborski_Juanita-Resume.pdf";

export default function About() {
  return (
    <section id="about" className="about__container anchor">
      {/* Abstract Images */}
      <div className="about__container-background">
        <img
          src={abstractGridLines}
          alt="Abstract grid with vertical lines."
          width="400px"
          height="auto"
        />
      </div>

      {/* About Content and Image */}
      <div className="container">
        <div className="row about__row">
          <div
            className="
                col-lg-6 col-md-6 col-sm-12 col-12 
                order-lg-1 order-md-1 order-sm-2 order-2"
          >
            <p>
              I am a Full Stack Developer with a passion for front-end development. Forever curious. Interested in the interesting.
              Perpetually looking for ways to create designs that are intuitive, clean and bold, and develop websites that ensure optimal functionality and user-friendliness.
            </p>
            <p>
              Since 2016, I have partnered with incredible non-profit
              organizations, medical associations, and small businesses to
              design and develop websites that streamlines their process,
              provides them with functionality that enhance their operations,
              and is useful and helpful to their users.
            </p>
            <p>
              In the summer of 2022, I joined the Portland Trail Blazers as a
              Software Developer and UX/UI Designer. I've since been designing and
              building tools for basketball operations.
            </p>
          </div>
          <div
            className="
                col-lg-6 col-md-6 col-sm-12 col-12 
                order-lg-2 order-md-2 order-sm-1 order-1"
          >
            <div>
              <img
                src={selfie}
                alt="Juanita Samborski looking over shoulder at the river."
                width="400px"
                height="auto"
              />
            </div>
            <div className="primary-button__container">
              <hr className="primary-button__line" />
              <a
                href={resumePDF}
                download="Samborski Resume"
                rel="noreferrer"
                target="_blank"
              >
                <button className="primary-button">View Resume</button>
              </a>
            </div>
          </div>
        </div>
      </div>

      {/* Scroll to Project Button */}
      <div className="borderless-button__container project-scroll">
        <hr className="borderless-button__line" />
        <button className="borderless-button">Projects</button>
      </div>
    </section>
  );
}
