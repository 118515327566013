import anodelivery from "../assets/images/Ano-Delivery.jpg";
import fileevictions from "../assets/images/FileEvictions-Welcome.png";
import bolivarthreads from "../assets/images/Bolivar-LAB.jpg";
import trailblazers from "../assets/images/blazers_court.png";

const proProjectsList = [
  {
    id: 1,
    title: "Trail Blazers",
    shortTitle: "Blazers",
    role: "UX/UI | Development | Project Management",
    number: "01",
    image: trailblazers,
    description: "Software for Basketball Operations",
    skills: [
      "Front-end Development",
      "Software UX/UI",
      "Graphic Design",
      "Project Management",
    ],
    slug: "trail-blazers",
  },
  {
    id: 2,
    title: "File Evictions",
    shortTitle: "Legal",
    role: "UX/UI | Development | Project Management",
    number: "02",
    image: fileevictions,
    description: "Software for Evictions.",
    skills: [
      "Website Design",
      "WordPress Development",
      "Software UX/UI",
      "Project Management",
    ],
    slug: "file-evictions",
  },
  {
    id: 3,
    title: "Ano Delivery",
    shortTitle: "Delivery",
    role: "Design | Front-end Development",
    number: "03",
    image: anodelivery,
    description: "Online food ordering and delivery platform.",
    skills: ["Website Redesign", "Front-end Development", "HTML", "CSS"],
    slug: "ano-delivery",
  },
  {
    id: 4,
    title: "Boliver Threads",
    shortTitle: "Shop",
    role: "Design | Development",
    number: "04",
    image: bolivarthreads,
    description: "Online monogram shop.",
    skills: [
      "Website Design",
      "WordPress Development",
      "Software UI",
      "eCommerce",
    ],
    slug: "bolivar-threads",
  },
];

export default proProjectsList;
