import myteamstats from "../assets/images/MyTeam-Stats.png";
import dogeared from "../assets/images/dogeared.png";
import holidaze from "../assets/images/holidaze.png";
import techtalk from "../assets/images/techTalk.png";
import weatherapp from "../assets/images/weather.png";

const personalProjectsList = [
  {
    id: 1,
    title: "Team Stats Tracker",
    overview:
      "React | Javascript | HTML | CSS | Express.js | MongoDB | GraphQL/Apollo",
    description:
      "Stats tracker for youth leagues, adult recreation teams, and school sports. Allows users to view upcoming games, scores, team and player statistics.",
    link: "https://github.com/jsamborski310/team-stats-tracker",
    image: myteamstats,
    siteLink: "https://team-stats-tracker-2022-0b3b16647706.herokuapp.com/",
  },
  {
    id: 2,
    title: "Dogeared",
    overview: "Javascript | Express.js | Handlebars | HTML | CSS",
    description:
      "This application was created as a space for readers to build a personal, digital library. Readers can attach notes and favorite quotes to the books in their library.",
    link: "https://github.com/jsamborski310/dogeared",
    image: dogeared,
    siteLink: "https://dogeared-2022.herokuapp.com/login",
  },
  {
    id: 3,
    title: "Holidaze",
    overview: "HTML | Javascript | CSS | API",
    description:
      "HoliDAZE is an application in which the user can search for a certain holiday with the option of filtering the results by country and date.",
    link: "https://github.com/jsamborski310/Holidaze",
    image: holidaze,
    siteLink: "https://jsamborski310.github.io/Holidaze/",
  },
  {
    id: 4,
    title: "Tech Talk",
    overview: "Javascript | HTML | CSS | Handlebars | Express.js | MySQL ",
    description:
      "A CMS-style blog site that allows users to publish articles, view blog posts, and post comments to share thoughts and opinions.",
    link: "https://github.com/jsamborski310/Tech-Talk",
    image: techtalk,
    siteLink: "https://js-tech-talk.herokuapp.com/",
  },
  {
    id: 5,
    title: "The Weather App",
    overview: "Javascript | HTML | CSS | Open Weather API",
    description:
      "A weather application so that a traveler may view weather information for various cities around the world so they may plan a trip accordingly.",
    link: "https://github.com/jsamborski310/The-Weather-App",
    image: weatherapp,
    siteLink: "https://jsamborski310.github.io/The-Weather-App/",
  },
];

export default personalProjectsList;
